
.meta-column-header {
  white-space: nowrap;
}

.list-column-header {
  position: relative;
  cursor: pointer;

  .col-sort-indicator {
    position: absolute;
    top: calc(50% + 1px);
    left: -5px;
    transform: translateY(-50%);
    z-index: 5;
    display: inline-block;
  }
}
